import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {supabase} from "../komponenty/utils/supabase";
import {Helmet} from "react-helmet";
import {motion} from "framer-motion";
import VagonMatcher from "../komponenty/rez/vagonMatcher";

function RezervaceMain() {

    const params = useParams();

    let [info, setInfo] = useState({carriages: [], seat_map: []});
    const [vagonyRecords, setVagonyRecords] = useState([]);
    const [selectedCarriage, setSelectedCarriage] = useState({id: 0, pos: 0});

    useEffect(() => {
        void findId();
    }, [])

    const findId = async () => {

        let id = params.rezervace_id.split('.')

        const { data } = await supabase
            .from('rezervace_system')
            .select()
            .eq('id', id[id.length - 1]);

        if (data.length !== 0) {
            setInfo(data[0]);
        } else {
            //TODO: Render a 404 page
        }
    }

    useEffect(() => {
        if (info.carriages && info.carriages.length > 0) {
            const fetchVagonyRecords = async () => {
                const { data, error } = await supabase
                    .from('vagony')
                    .select('id, obrazek')
                    // Using .in() lets you fetch all records with IDs in the array
                    .in('id', info.carriages);

                if (error) {
                    console.error("Error fetching vagony records:", error);
                } else if (data) {
                    setVagonyRecords(data);
                }
            };

            fetchVagonyRecords();
        }
    }, [info.carriages]);

    return (
        <>
            <Helmet>
                <title>Rezervace | Posázavský Pacifik</title>
            </Helmet>
            <motion.div id={"page-container"}
                        className={'padding-page-top'}
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0}}
                        transition={{duration: .25, ease: 'easeOut'}}
                        style={{display: "flex", flexDirection: "column", alignItems: "center"}}
            >
			
			<h1>{info.name}</h1>

                <div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: "90%"}}>
                    {info.carriages.map((id, index) => {
                        // Find the record with a matching ID.
                        // We use String() conversion in case the IDs are stored as numbers vs. strings.
                        const record = vagonyRecords.find(v => String(v.id) === String(id));
                        if (!record) return null; // In case a record is not found

                        return (
                            <div key={`${id}-${index}`} onClick={() => setSelectedCarriage({id: id, pos: index})} style={{cursor: "pointer"}}>
                                <img src={record.obrazek} alt={`Vagon ${id}`} style={{ width: "100%" }} />
                            </div>
                        );
                    })}
                </div>

                <div style={{width: "90%", display: "flex", justifyContent: "center", marginTop: "20px"}}>
                    <VagonMatcher id={selectedCarriage.id} seatMap={info.seat_map[selectedCarriage.pos]} />
                </div>

            </motion.div>
        </>
    );
}

export default RezervaceMain;
import React, {useState} from "react";
import {motion} from "framer-motion";
import {Helmet} from "react-helmet";
import RezVagBtx761 from "../../komponenty/rez/vagony/btx761";
import RezVagBtx761Bar from "../../komponenty/rez/vagony/btx761bar";



export default function RezTest() {

    return (
        <>
            <Helmet>
                <title>Test | Posázavský Pacifik</title>
            </Helmet>
            <motion.div id={"page-container"}
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0}}
                        transition={{duration: .25, ease: 'easeOut'}}
                        style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}
            >


            </motion.div>
        </>
    );

}